import React, {FunctionComponent, ReactNode} from "react"
import {DealInterface} from "../../deal.interface"
import styles from "./head.module.sass"
import Image from "../../../../tech/image/image.component"

type DealHeadProps = {
    deal: DealInterface
}

const DealHead: FunctionComponent<DealHeadProps> = ({ deal }) => {
    return (
        <>
            <div className={styles.head}>
                <div className={styles.logo}>
                    <div>
                        <LogoWithTitle
                            name={deal.name}
                            logo={deal.logo && (
                                <Image
                                    image={deal.logo}
                                    height={56}
                                    alt={deal.name || ""}
                                    fullyRounded={true}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DealHead

const LogoWithTitle: FunctionComponent<{ name?: string, logo?: ReactNode }> = ({ name, logo }) => {
    return (
        <div>
            {logo && name ? (
                <div className={styles.logoWithTitle}>
                    <div>
                        {logo}
                    </div>
                    <div className={`${styles.title} ${styles.titleAndLogo}`}>
                        <div>
                            {name}
                        </div>
                    </div>
                </div>
            ) : name ? (
                <div className={styles.title}>
                    {name}
                </div>
            ) : <></>}
        </div>
    )
}