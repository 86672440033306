import {ErrorState} from "./state.type"

export function mapErrorToString(state: ErrorState): string {
    switch (state) {
        case "ERROR_USER_WITH_SAME_EMAIL_ALREADY_EXISTS":
            return "A user with this email address already exists."
        case "ERROR":
        default:
            return "Something went wrong. Please try again."
    }
}

export function evaluateError(err: unknown): ErrorState {
    const errorString = err ? err.toString() : ""
    if (errorString.includes("UserLambdaValidationException")) {
        return evaluateErrorUserLambdaValidationException(errorString)
    }
    if (errorString.includes("UsernameExistsException")) {
        return "ERROR_USER_WITH_SAME_EMAIL_ALREADY_EXISTS"
    }
    return "ERROR"
}

function evaluateErrorUserLambdaValidationException(err: string): ErrorState {
    if (err.includes("USER_WITH_SAME_EMAIL_ALREADY_EXISTS")) {
        return "ERROR_USER_WITH_SAME_EMAIL_ALREADY_EXISTS"
    }
    return "ERROR"
}