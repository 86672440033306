import {DealType} from "../../deal.type"
import {InvestmentType} from "../../../investment/investment.type"

export function evaluateState(
    deal: DealType,
    investment: InvestmentType | undefined
): "INVEST" | "CLOSED" | undefined {
    return investment !== undefined
        ? undefined
        : evaluateActionUserHasNotAnInvestment(deal)
}

function evaluateActionUserHasNotAnInvestment(deal: DealType): "INVEST" | "CLOSED" | undefined {
    if (isClosedForFurtherInvestments(deal)) return "CLOSED"
    if (deal.outcome !== "NONE") return undefined
    return "INVEST"
}

function isClosedForFurtherInvestments(deal: DealType): boolean {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    const finalDeadline = deal._finalDeadline
    finalDeadline.setHours(0, 0, 0, 0)
    return deal.waitingState === "WAITING_FOR_PAYMENTS"
        && (deal._investedAmount >= deal._maxTarget || finalDeadline < now)
}