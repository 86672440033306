import React, {FunctionComponent} from "react"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import {DealType} from "../../deal.type"
import styles from "./head.module.sass"
import Image from "../../../../tech/image/image.component"

type DealHeadProps = {
    deal: DealOnboardingDependentType
}

const DealHead: FunctionComponent<DealHeadProps> = ({ deal }) => {
    return (
        <div className={styles.head}>
            <div className={styles.logoAndTitle}>
                {deal instanceof DealType && deal.logo && (
                    <div className={styles.logo}>
                        <div>
                            <Image
                                image={deal.logo}
                                height={50}
                                alt={deal.name}
                                fullyRounded={true}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.title}>
                    {deal.name}
                </div>
            </div>
        </div>
    )
}

export default DealHead