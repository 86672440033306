/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DealFeeStructureOpenApi,
  DealFiltersOpenApi,
  DealInvestmentFeesResponseOpenApi,
  DealInvestmentInitializationOpenApi,
  DealOnboardingDependentWithInvestmentOpenApi,
  DealOpenApi,
  DealPaginatedResponseOpenApi,
  DealPublicationValidationPreviewOpenApi,
  DealQuestionAddRequestOpenApi,
  DealQuestionOpenApi,
  DealSetOutcomeRequestOpenApi,
  DealSortOpenApi,
  DealWithSyndicatePublicOpenApi,
  DealWithValuationsOpenApi,
  DealWithValuationsPaginatedResponseOpenApi,
  DealWithValuationsSortOpenApi,
  FavouriteDealToggleResultOpenApi,
  InvestmentOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    DealFeeStructureOpenApiFromJSON,
    DealFeeStructureOpenApiToJSON,
    DealFiltersOpenApiFromJSON,
    DealFiltersOpenApiToJSON,
    DealInvestmentFeesResponseOpenApiFromJSON,
    DealInvestmentFeesResponseOpenApiToJSON,
    DealInvestmentInitializationOpenApiFromJSON,
    DealInvestmentInitializationOpenApiToJSON,
    DealOnboardingDependentWithInvestmentOpenApiFromJSON,
    DealOnboardingDependentWithInvestmentOpenApiToJSON,
    DealOpenApiFromJSON,
    DealOpenApiToJSON,
    DealPaginatedResponseOpenApiFromJSON,
    DealPaginatedResponseOpenApiToJSON,
    DealPublicationValidationPreviewOpenApiFromJSON,
    DealPublicationValidationPreviewOpenApiToJSON,
    DealQuestionAddRequestOpenApiFromJSON,
    DealQuestionAddRequestOpenApiToJSON,
    DealQuestionOpenApiFromJSON,
    DealQuestionOpenApiToJSON,
    DealSetOutcomeRequestOpenApiFromJSON,
    DealSetOutcomeRequestOpenApiToJSON,
    DealSortOpenApiFromJSON,
    DealSortOpenApiToJSON,
    DealWithSyndicatePublicOpenApiFromJSON,
    DealWithSyndicatePublicOpenApiToJSON,
    DealWithValuationsOpenApiFromJSON,
    DealWithValuationsOpenApiToJSON,
    DealWithValuationsPaginatedResponseOpenApiFromJSON,
    DealWithValuationsPaginatedResponseOpenApiToJSON,
    DealWithValuationsSortOpenApiFromJSON,
    DealWithValuationsSortOpenApiToJSON,
    FavouriteDealToggleResultOpenApiFromJSON,
    FavouriteDealToggleResultOpenApiToJSON,
    InvestmentOpenApiFromJSON,
    InvestmentOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface DealAssignCurrentInvestorRequest {
    dealIds: Array<string>;
}

export interface DealCreateRequest {
    dealOpenApi?: DealOpenApi;
}

export interface DealDeleteRequest {
    id: string;
}

export interface DealGetRequest {
    id: string;
}

export interface DealGetAllOpenRequest {
    exclusivesOnly?: boolean;
    favouritesOnly?: boolean;
}

export interface DealGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: DealSortOpenApi;
    dealFiltersOpenApi?: DealFiltersOpenApi;
}

export interface DealGetAllWithValuationsPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: DealWithValuationsSortOpenApi;
    dealFiltersOpenApi?: DealFiltersOpenApi;
}

export interface DealGetFeeStructureForCurrentUserRequest {
    id: string;
}

export interface DealGetFeesForCurrentUserRequest {
    id: string;
    amount: number;
}

export interface DealGetPublicRequest {
    id: string;
}

export interface DealGetSignedContractsZipRequest {
    id: string;
}

export interface DealInitializeInvestmentRequest {
    id: string;
    dealInvestmentInitializationOpenApi?: DealInvestmentInitializationOpenApi;
}

export interface DealOnboardingDependentWithInvestmentGetRequest {
    id: string;
}

export interface DealPublicationValidationPreviewRequest {
    id: string;
}

export interface DealPublishRequest {
    id: string;
}

export interface DealQuestionAddRequest {
    id: string;
    dealQuestionAddRequestOpenApi?: DealQuestionAddRequestOpenApi;
}

export interface DealQuestionGetAllRequest {
    id: string;
}

export interface DealQuestionToggleResolveStatusRequest {
    id: string;
    questionId: string;
}

export interface DealSendOutPaymentInstructionsRequest {
    id: string;
}

export interface DealSetOutcomeRequest {
    id: string;
    dealSetOutcomeRequestOpenApi?: DealSetOutcomeRequestOpenApi;
}

export interface DealToggleFavouriteRequest {
    id: string;
}

export interface DealUnpublishRequest {
    id: string;
}

export interface DealUpdateRequest {
    id: string;
    dealOpenApi?: DealOpenApi;
}

export interface DealWithValuationsGetRequest {
    id: string;
}

/**
 * 
 */
export class DealApiClient extends runtime.BaseAPI {

    /**
     * Assign current investor to deal
     */
    async dealAssignCurrentInvestorRaw(requestParameters: DealAssignCurrentInvestorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dealIds === null || requestParameters.dealIds === undefined) {
            throw new runtime.RequiredError('dealIds','Required parameter requestParameters.dealIds was null or undefined when calling dealAssignCurrentInvestor.');
        }

        const queryParameters: any = {};

        if (requestParameters.dealIds) {
            queryParameters['dealIds'] = requestParameters.dealIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/assign-current-investor`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign current investor to deal
     */
    async dealAssignCurrentInvestor(requestParameters: DealAssignCurrentInvestorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dealAssignCurrentInvestorRaw(requestParameters, initOverrides);
    }

    /**
     * Create new deal
     */
    async dealCreateRaw(requestParameters: DealCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DealOpenApiToJSON(requestParameters.dealOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new deal
     */
    async dealCreate(requestParameters: DealCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealOpenApi> {
        const response = await this.dealCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete deal by id
     */
    async dealDeleteRaw(requestParameters: DealDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete deal by id
     */
    async dealDelete(requestParameters: DealDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dealDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get deal by id
     */
    async dealGetRaw(requestParameters: DealGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealOpenApiFromJSON(jsonValue));
    }

    /**
     * Get deal by id
     */
    async dealGet(requestParameters: DealGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealOpenApi> {
        const response = await this.dealGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all deals
     */
    async dealGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealOpenApiFromJSON));
    }

    /**
     * Get all deals
     */
    async dealGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealOpenApi>> {
        const response = await this.dealGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all deals (onboarding dependent) with investment open to new investments
     */
    async dealGetAllOpenRaw(requestParameters: DealGetAllOpenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealOnboardingDependentWithInvestmentOpenApi>>> {
        const queryParameters: any = {};

        if (requestParameters.exclusivesOnly !== undefined) {
            queryParameters['exclusivesOnly'] = requestParameters.exclusivesOnly;
        }

        if (requestParameters.favouritesOnly !== undefined) {
            queryParameters['favouritesOnly'] = requestParameters.favouritesOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/open`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealOnboardingDependentWithInvestmentOpenApiFromJSON));
    }

    /**
     * Get all deals (onboarding dependent) with investment open to new investments
     */
    async dealGetAllOpen(requestParameters: DealGetAllOpenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealOnboardingDependentWithInvestmentOpenApi>> {
        const response = await this.dealGetAllOpenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all deals (paginated)
     */
    async dealGetAllPaginatedRaw(requestParameters: DealGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealPaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DealFiltersOpenApiToJSON(requestParameters.dealFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealPaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all deals (paginated)
     */
    async dealGetAllPaginated(requestParameters: DealGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealPaginatedResponseOpenApi> {
        const response = await this.dealGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all deals with valuations (paginated)
     */
    async dealGetAllWithValuationsPaginatedRaw(requestParameters: DealGetAllWithValuationsPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealWithValuationsPaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/with-valuations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DealFiltersOpenApiToJSON(requestParameters.dealFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealWithValuationsPaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all deals with valuations (paginated)
     */
    async dealGetAllWithValuationsPaginated(requestParameters: DealGetAllWithValuationsPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealWithValuationsPaginatedResponseOpenApi> {
        const response = await this.dealGetAllWithValuationsPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all deals without a company
     */
    async dealGetAllWithoutCompanyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/without-company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealOpenApiFromJSON));
    }

    /**
     * Get all deals without a company
     */
    async dealGetAllWithoutCompany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealOpenApi>> {
        const response = await this.dealGetAllWithoutCompanyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all deals without an update
     */
    async dealGetAllWithoutUpdateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/without-update`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealOpenApiFromJSON));
    }

    /**
     * Get all deals without an update
     */
    async dealGetAllWithoutUpdate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealOpenApi>> {
        const response = await this.dealGetAllWithoutUpdateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all deals without a valuation
     */
    async dealGetAllWithoutValuationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/without-valuation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealOpenApiFromJSON));
    }

    /**
     * Get all deals without a valuation
     */
    async dealGetAllWithoutValuation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealOpenApi>> {
        const response = await this.dealGetAllWithoutValuationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get fes on deal for current user
     */
    async dealGetFeeStructureForCurrentUserRaw(requestParameters: DealGetFeeStructureForCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealFeeStructureOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGetFeeStructureForCurrentUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/fee-structure`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealFeeStructureOpenApiFromJSON(jsonValue));
    }

    /**
     * Get fes on deal for current user
     */
    async dealGetFeeStructureForCurrentUser(requestParameters: DealGetFeeStructureForCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealFeeStructureOpenApi> {
        const response = await this.dealGetFeeStructureForCurrentUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dealGetFeesForCurrentUserRaw(requestParameters: DealGetFeesForCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealInvestmentFeesResponseOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGetFeesForCurrentUser.');
        }

        if (requestParameters.amount === null || requestParameters.amount === undefined) {
            throw new runtime.RequiredError('amount','Required parameter requestParameters.amount was null or undefined when calling dealGetFeesForCurrentUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/fees/{amount}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"amount"}}`, encodeURIComponent(String(requestParameters.amount))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealInvestmentFeesResponseOpenApiFromJSON(jsonValue));
    }

    /**
     */
    async dealGetFeesForCurrentUser(requestParameters: DealGetFeesForCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealInvestmentFeesResponseOpenApi> {
        const response = await this.dealGetFeesForCurrentUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get deal by id (public)
     */
    async dealGetPublicRaw(requestParameters: DealGetPublicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealWithSyndicatePublicOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGetPublic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-public/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealWithSyndicatePublicOpenApiFromJSON(jsonValue));
    }

    /**
     * Get deal by id (public)
     */
    async dealGetPublic(requestParameters: DealGetPublicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealWithSyndicatePublicOpenApi> {
        const response = await this.dealGetPublicRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get ZIP download URL of all signed deal contracts
     */
    async dealGetSignedContractsZipRaw(requestParameters: DealGetSignedContractsZipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGetSignedContractsZip.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/contract/download/signed`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get ZIP download URL of all signed deal contracts
     */
    async dealGetSignedContractsZip(requestParameters: DealGetSignedContractsZipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.dealGetSignedContractsZipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initialize investment on a deal
     */
    async dealInitializeInvestmentRaw(requestParameters: DealInitializeInvestmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestmentOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealInitializeInvestment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/investment/initialization`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DealInvestmentInitializationOpenApiToJSON(requestParameters.dealInvestmentInitializationOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestmentOpenApiFromJSON(jsonValue));
    }

    /**
     * Initialize investment on a deal
     */
    async dealInitializeInvestment(requestParameters: DealInitializeInvestmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestmentOpenApi> {
        const response = await this.dealInitializeInvestmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get deal (onboarding dependent) by deal id with potential investment of current user
     */
    async dealOnboardingDependentWithInvestmentGetRaw(requestParameters: DealOnboardingDependentWithInvestmentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealOnboardingDependentWithInvestmentOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealOnboardingDependentWithInvestmentGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/onboarding-dependent-with-investment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealOnboardingDependentWithInvestmentOpenApiFromJSON(jsonValue));
    }

    /**
     * Get deal (onboarding dependent) by deal id with potential investment of current user
     */
    async dealOnboardingDependentWithInvestmentGet(requestParameters: DealOnboardingDependentWithInvestmentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealOnboardingDependentWithInvestmentOpenApi> {
        const response = await this.dealOnboardingDependentWithInvestmentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview validation on deal publication
     */
    async dealPublicationValidationPreviewRaw(requestParameters: DealPublicationValidationPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealPublicationValidationPreviewOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealPublicationValidationPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealPublicationValidationPreviewOpenApiFromJSON(jsonValue));
    }

    /**
     * Preview validation on deal publication
     */
    async dealPublicationValidationPreview(requestParameters: DealPublicationValidationPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealPublicationValidationPreviewOpenApi> {
        const response = await this.dealPublicationValidationPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish deal
     */
    async dealPublishRaw(requestParameters: DealPublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealPublish.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealOpenApiFromJSON(jsonValue));
    }

    /**
     * Publish deal
     */
    async dealPublish(requestParameters: DealPublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealOpenApi> {
        const response = await this.dealPublishRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Q&A question to deal
     */
    async dealQuestionAddRaw(requestParameters: DealQuestionAddRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealQuestionOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealQuestionAdd.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/question`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DealQuestionAddRequestOpenApiToJSON(requestParameters.dealQuestionAddRequestOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealQuestionOpenApiFromJSON(jsonValue));
    }

    /**
     * Add Q&A question to deal
     */
    async dealQuestionAdd(requestParameters: DealQuestionAddRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealQuestionOpenApi> {
        const response = await this.dealQuestionAddRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Q&A questions of a deal
     */
    async dealQuestionGetAllRaw(requestParameters: DealQuestionGetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealQuestionOpenApi>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealQuestionGetAll.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/question`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealQuestionOpenApiFromJSON));
    }

    /**
     * Get all Q&A questions of a deal
     */
    async dealQuestionGetAll(requestParameters: DealQuestionGetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealQuestionOpenApi>> {
        const response = await this.dealQuestionGetAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle resolve status of Q&A question
     */
    async dealQuestionToggleResolveStatusRaw(requestParameters: DealQuestionToggleResolveStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealQuestionOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealQuestionToggleResolveStatus.');
        }

        if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
            throw new runtime.RequiredError('questionId','Required parameter requestParameters.questionId was null or undefined when calling dealQuestionToggleResolveStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/question/{questionId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"questionId"}}`, encodeURIComponent(String(requestParameters.questionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealQuestionOpenApiFromJSON(jsonValue));
    }

    /**
     * Toggle resolve status of Q&A question
     */
    async dealQuestionToggleResolveStatus(requestParameters: DealQuestionToggleResolveStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealQuestionOpenApi> {
        const response = await this.dealQuestionToggleResolveStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send out payment instructions (for deals with manual payment instructions trigger only)
     */
    async dealSendOutPaymentInstructionsRaw(requestParameters: DealSendOutPaymentInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealSendOutPaymentInstructions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/send-payment-instructions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send out payment instructions (for deals with manual payment instructions trigger only)
     */
    async dealSendOutPaymentInstructions(requestParameters: DealSendOutPaymentInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dealSendOutPaymentInstructionsRaw(requestParameters, initOverrides);
    }

    /**
     * Set outcome of deal
     */
    async dealSetOutcomeRaw(requestParameters: DealSetOutcomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealSetOutcome.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/outcome`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DealSetOutcomeRequestOpenApiToJSON(requestParameters.dealSetOutcomeRequestOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealOpenApiFromJSON(jsonValue));
    }

    /**
     * Set outcome of deal
     */
    async dealSetOutcome(requestParameters: DealSetOutcomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealOpenApi> {
        const response = await this.dealSetOutcomeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle favourite status for given and current investor
     */
    async dealToggleFavouriteRaw(requestParameters: DealToggleFavouriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavouriteDealToggleResultOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealToggleFavourite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/favourite`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavouriteDealToggleResultOpenApiFromJSON(jsonValue));
    }

    /**
     * Toggle favourite status for given and current investor
     */
    async dealToggleFavourite(requestParameters: DealToggleFavouriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavouriteDealToggleResultOpenApi> {
        const response = await this.dealToggleFavouriteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unpublish deal
     */
    async dealUnpublishRaw(requestParameters: DealUnpublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealUnpublish.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/unpublish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealOpenApiFromJSON(jsonValue));
    }

    /**
     * Unpublish deal
     */
    async dealUnpublish(requestParameters: DealUnpublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealOpenApi> {
        const response = await this.dealUnpublishRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update deal
     */
    async dealUpdateRaw(requestParameters: DealUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DealOpenApiToJSON(requestParameters.dealOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealOpenApiFromJSON(jsonValue));
    }

    /**
     * Update deal
     */
    async dealUpdate(requestParameters: DealUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealOpenApi> {
        const response = await this.dealUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get deal by id with valuations
     */
    async dealWithValuationsGetRaw(requestParameters: DealWithValuationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealWithValuationsOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealWithValuationsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal/{id}/with-valuations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealWithValuationsOpenApiFromJSON(jsonValue));
    }

    /**
     * Get deal by id with valuations
     */
    async dealWithValuationsGet(requestParameters: DealWithValuationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealWithValuationsOpenApi> {
        const response = await this.dealWithValuationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
