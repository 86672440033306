import {AuthTokens, fetchAuthSession} from "aws-amplify/auth"
import {
    Configuration,
    DealApiClient,
    FAQEntryApiClient,
    FileApiClient,
    IndividualInvestmentApiClient,
    InvestmentApiClient,
    InvestorApiClient,
    InvestorDashboardApiClient,
    LoggingApiClient,
    PreflightApiClient,
    SubscriptionApiClient,
    SyndicateApiClient
} from "../../generated"
import {DealApi} from "../../domain/deal/deal.api"
import {FileApi} from "../../domain/file/file.api"
import {InvestorApi} from "../../domain/investor/investor.api"
import {InvestmentApi} from "../../domain/investment/investment.api"
import {DashboardApi} from "../../domain/investor/dashboard/dashboard.api"
import {LoggingApi} from "../logging/logging.api"
import {RequestErrorMiddleware} from "../logging/error-middleware"
import {getEnvironment} from "../environment/environment.util"
import {Environment} from "../environment/environment.enum"
import {SubscriptionApi} from "../../domain/subscription/subscription.api"
import {FaqEntryApi} from "../../domain/help/faq/entry/faq-entry.api"
import {IndividualInvestmentApi} from "../../domain/investment/individual/individual-investment.api"
import {PreflightApi} from "../preflight/preflight.api"
import {SyndicateApi} from "../../domain/syndicate/syndicate.api"

export class FetchClient {

    // @ts-ignore
    private apis: APIs

    constructor(initialTokens: AuthTokens | undefined) {
        this.initializeApis(this.assembleConfiguration(initialTokens))
        setInterval(async () => {
            await this.updateAccessToken()
        }, 120_000) // refresh token every 2 minutes
    }

    get dealApi(): DealApi {
        return new DealApi(this.apis.dealApiClient);
    }

    get faqEntryApi(): FaqEntryApi {
        return new FaqEntryApi(this.apis.faqEntryApiClient)
    }

    get fileApi(): FileApi {
        return new FileApi(this.apis.fileApiClient);
    }

    get individualInvestmentApi(): IndividualInvestmentApi {
        return new IndividualInvestmentApi(this.apis.individualInvestmentApiClient)
    }

    get investmentApi(): InvestmentApi {
        return new InvestmentApi(this.apis.investmentApiClient)
    }

    get investorApi(): InvestorApi {
        return new InvestorApi(this.apis.investorApiClient)
    }

    get investorDashboardApi(): DashboardApi {
        return new DashboardApi(this.apis.investorDashboardApiClient)
    }

    get loggingApi(): LoggingApi {
        return new LoggingApi(this.apis.loggingApiClient)
    }

    get preflightApi(): PreflightApi {
        return new PreflightApi(this.apis.preflightApiClient)
}

    get subscriptionApi(): SubscriptionApi {
        return new SubscriptionApi(this.apis.subscriptionApiClient)
    }

    get syndicateApi(): SyndicateApi {
        return new SyndicateApi(this.apis.syndicateApiClient)
    }

    public async updateAccessToken() {
        try {
            const authSession = await fetchAuthSession()
            this.initializeApis(this.assembleConfiguration(authSession.tokens))
        }
        catch (err) {
            console.error("Failed to fetch auth session", err)
        }
    }

    private assembleConfiguration(accessToken: AuthTokens | undefined) {
        return new Configuration({
            basePath: this.getBasePath(),
            headers: this.assembleHeaders(accessToken),
            middleware: [ new RequestErrorMiddleware(this) ]
        })
    }

    private initializeApis(configuration: Configuration) {
        this.apis = {
            dealApiClient: new DealApiClient(configuration),
            faqEntryApiClient: new FAQEntryApiClient(configuration),
            fileApiClient: new FileApiClient(configuration),
            individualInvestmentApiClient: new IndividualInvestmentApiClient(configuration),
            investmentApiClient: new InvestmentApiClient(configuration),
            investorApiClient: new InvestorApiClient(configuration),
            investorDashboardApiClient: new InvestorDashboardApiClient(configuration),
            loggingApiClient: new LoggingApiClient(configuration),
            preflightApiClient: new PreflightApiClient(configuration),
            subscriptionApiClient: new SubscriptionApiClient(configuration),
            syndicateApiClient: new SyndicateApiClient(configuration),
        }
    }

    private getBasePath() {
        switch (getEnvironment()) {
            case Environment.DEVELOP:
            case Environment.PREVIEW:
                return "https://backend-integration.nonpublic.io"
            case Environment.PRODUCTION:
                return "https://backend.nonpublic.io"
            case Environment.LOCAL:
                return "http://localhost:8080"
        }
    }

    private assembleHeaders(accessToken: AuthTokens | undefined) {
        return accessToken
            ? {"Authorization": `Bearer ${accessToken.accessToken.toString()}`}
            : undefined
    }

}

type APIs = {
    dealApiClient: DealApiClient
    faqEntryApiClient: FAQEntryApiClient
    fileApiClient: FileApiClient
    individualInvestmentApiClient: IndividualInvestmentApiClient
    investmentApiClient: InvestmentApiClient
    investorApiClient: InvestorApiClient
    investorDashboardApiClient: InvestorDashboardApiClient
    loggingApiClient: LoggingApiClient
    preflightApiClient: PreflightApiClient
    subscriptionApiClient: SubscriptionApiClient
    syndicateApiClient: SyndicateApiClient
}