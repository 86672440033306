import React, {FunctionComponent} from "react"
import Imgix from "react-imgix"
import {FileType} from "../../domain/file/file.type"
import styles from "./image.module.sass"

type ImageProps = {
    image: FileType
    height: number
    alt: string
    fullyRounded?: boolean
}

const Image: FunctionComponent<ImageProps> = ({
    image,
    height,
    alt,
    fullyRounded = false
}) => {
    let imageElement = image.source.type === "IMGIX_SCR_SET"
        ?  (
            <img
                srcSet={image.source.src}
                alt={alt}
                style={{ maxHeight: height }}
            />
        ) : (
            <Imgix
                src={image.source.src}
                height={height}
                htmlAttributes={{ alt }}
            />
        )

    return fullyRounded
        ? <div className={styles.rounded}>{imageElement}</div>
        : imageElement
}

export default Image