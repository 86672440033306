import React, {FunctionComponent} from "react"
import {InvestorDashboardUnderlyingInvestmentType} from "../investment.type"
import Image from "../../../../../tech/image/image.component"
import styles from "./logo.module.sass"

type InvestorDashboardInvestmentLogoProps = {
    investment: InvestorDashboardUnderlyingInvestmentType
}

const InvestorDashboardInvestmentLogo: FunctionComponent<InvestorDashboardInvestmentLogoProps> = ({ investment }) => {
    const deal = investment.investment.type === "REGULAR"
        ? investment.investment.value.deal
        : undefined
    return (
        <div>
            <div className={styles.logoAndTitle}>
                {deal?.logo && (
                    <div className={styles.logo}>
                        <Image
                            image={deal.logo}
                            height={28}
                            alt={deal.name}
                            fullyRounded={true}
                        />
                    </div>
                )}
                <div className={styles.title}>
                    <div>
                        {investment.getName()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestorDashboardInvestmentLogo